import { useTable } from 'contexts/tableContext';
import { useProjects } from 'contexts/projectsContext';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom'; // Importar hook para detectar cambios en la URL

const relationFilterCategories: any = {
    articleCategoryId: 'articleCategories',
    materialCategoryId: 'materialCategories',
    materialId: 'materials',
    originId: 'origins',
    rawMaterialCategoryId: 'rawMaterialCategories',
    rawMaterialProcessId: 'rawMaterialProcesses',
    transportCategoryName: 'transportCategories',
    wetProcessId: 'wetProcesses',
};

export interface FilterItem {
    key: string;
    values: string[];
}

export default function FiltersManager() {
    const { filtersApplied, setFiltersApplied, cleanFilters, cleanSingleFilter, cleanGroupFilters } = useTable();
    const { filtersCategoriesByProject } = useProjects();
    const location = useLocation(); 

    useEffect(() => {
        cleanFilters();
    }, [location.pathname]); 

    const filters: FilterItem[] = Object.entries(filtersApplied || {}).map(
        ([key, values]) => ({
            key,
            values: (values as string[]).map((id) => id),
        })
    );

    if (!filters.some(filter => filter.values.length > 0)) return null;

    const getFilterName = (key: string, id: string) => {
        const category = relationFilterCategories[key];
        if (!category || !filtersCategoriesByProject[category]) return id;

        const item = filtersCategoriesByProject[category].find((item: any) => item.id === id);
        return item ? item.name : id;
    };

    return (
        <div className="d-flex flex-wrap py-2 mb-4 ml-3 font-14px" style={{ gap: '8px' }}>
            {filters.map(({ key, values }) =>
                values.map((value) => (
                    <div key={`${key}-${value}`} className="text-dark-60 d-flex align-items-center" style={{ gap: '8px' }}>
                        <span>{getFilterName(key, value)}</span>
                        <span
                            className="icon-icon-close m-1 cursor-pointer"
                            onClick={() => cleanSingleFilter(key, value)}
                            style={{ marginLeft: '8px', cursor: 'pointer' }}
                        ></span>
                    </div>
                ))
            )}
            <button
                className="font-sfpro-regular"
                style={{ background: 'none', border: 'none', cursor: 'pointer', padding: '4px' }}
                onClick={cleanFilters}
            >
                Reset
            </button>
        </div>
    );
}
