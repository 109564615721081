import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useProjects } from "contexts/projectsContext";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import { IPresetView } from "UI/molecules/SelectorViews/ViewsTypes";
import { EMetricNames, ESchemasIds } from "UI/Template/Table/types/TableEnums";
import ViewsHttp from "services/http/Views";
import ArticleHttp from "services/http/Article";
import { schemaGroups } from "UI/Template/Table/types/SchemaTypes";
import columnsSchema, { columnsSchemaById } from "UI/Template/Table/schemas/columns.schema";
import Notification from "../components/Elements/Notification";
import 'react-toastify/dist/ReactToastify.css';
import { notificationBtn } from "components/Elements/NotificationElements";
import { useTranslation } from "react-i18next";
import FilterHttp from 'services/http/Filters';
import { useValidateSmartLabel } from "hooks/useValidateSmartLabel.hook";

const TableContext = React.createContext<any>(null);
export const useTable = () => useContext(TableContext);

export interface ResTable {
    datas: any[];
    total: number;
    pageCount: number;
}

interface Filters {
    articleCategoryId: string[],
    materialId: string[],
    materialCategoryId: string[],
    rawMaterialProcessId: string[],
    rawMaterialCategoryId: string[],
    wetProcessId: string[],
    transportCategoryName: string[],
    originId: string[]
}

const Views = new ViewsHttp();
const Filters = new FilterHttp();
const initialFilters: Filters = ({
    articleCategoryId: [],
    materialId: [],
    materialCategoryId: [],
    rawMaterialProcessId: [],
    rawMaterialCategoryId: [],
    wetProcessId: [],
    transportCategoryName: [],
    originId: []
})

export const TableContextProvider = ({ children }: any): any => {
    const { validate } = useValidateSmartLabel();
    const { t }: any = useTranslation();
    const h = useHistory()
    const { projectSelected, productSelected, openModalSaveChanges, setOpenModalSaveChanges } = useProjects();

    const [res, setRes] = useState<ResTable>({ datas: [], total: 0, pageCount: 0 })
    const items = res.datas || []
    const pageCount = res.pageCount || 0;

    const [dataGroupSelected, setDataGroupSelected] = useState<ESchemasIds>(ESchemasIds.indicators);
    const [searchText, setSearchText] = useState<string>("");
    const [sortMetric, setSortMetric] = useState<[]>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [limitPagination, setLimitPagination] = useState<number>(25);
    const [presetViews, setPresetViews] = useState<IPresetView[]>([]);
    const [areThereViews, setAreThereViews] = useState("")
    const [viewsByProduct, setViewsByProduct] = useState<IPresetView[]>([]);
    const [selectedView, setSelectedView] = useState<IPresetView>();
    const [sortColumnId, setsortColumnId] = useState<string>("");

    const [modalViewActionSelected, setModalViewActionSelected] = useState("");

    const [itemSelected, setItemSelected] = useState<any>()

    const [openColumnSelectorModal, setOpenColumnSelectorModal] = useState<boolean>(false);
    const [columnSelectorColumns, setColumnSelectorColumns] = useState<EMetricNames[] | null>(null);
    const [columnSelectorGrouping, setColumnSelectorGrouping] = useState<ESchemasIds | null>(null);

    const [openDetailScopeModal, setOpenDetailScopeModal] = useState<boolean>(false);
    const [openFiltersModal, setOpenFiltersModal] = useState<boolean>(false);
    const [sectionFilterSelectedToOpen, setSectionFilterSelectedToOpen] = useState<string>('')
    const [filtersApplied, setFiltersApplied] = useState<Filters>(initialFilters);
    const [filtersModified, setFiltersModified] = useState<Filters>(initialFilters);

    const [newViewName, setNewViewName] = useState("");
    const [customView, setCustomView] = useState<IPresetView>();
    const [dataGroupSelectedColumnSelector, setDataGroupSelectedColumnSelector] = useState<ESchemasIds>(dataGroupSelected);

    const [isLoading, setIsLoading] = useState(false);

    const [infiniteScrollLoading, setInfiniteScrollLoading] = useState(false);

    // Handle show/hide benchmark data in `LCA`
    const [enableBenchmarkData, setEnableBenchmarkData] = useState(false);

    const path = h.location.pathname.split('/')[2]

    const handleLoading = ((b: boolean) => {
        setIsLoading(b);
    });

    const getAllViews = () => {
        const request = Views.allViews();
        request.then((res) => {
            const traceabilityMockView: {[key: string]: any} = { // for test reasons
                "articles": {
                    "name": "articleList",
                    "groupDropdown": "global",
                    "schemaName": "traceability",
                    "product": "plm",
                    "columns": [
                        {
                            "group": "articleSummary",
                            "metric": [
                                "articleName", "category", "amountArticle", "totalArticleMaterial", "Traceability_Suppliers", "Traceability_Certificates"
                            ]
                        },
                        {
                            "group": "completion",
                            "metric": [
                                "Supply_Chain_Knowledge"
                            ]
                        }
                    ]
                },
                "suppliers": {
                    "name": "suppliersList",
                    "groupDropdown": "global",
                    "schemaName": "traceability",
                    "product": "plm",
                    "columns": [
                        {
                            "group": "supplierDescription",
                            "metric": [
                                "supplierName","supplier_tiers", "supplier_origin_abbreviation"
                            ]
                        },
                        {
                            "group": "logistics",
                            "metric": [
                                "categoryId", "supplier_transport_category"
                            ]
                        },
                        {
                            "group": "certificateLists",
                            "metric": [
                                "supplier_certificate_names"
                            ]
                        },
                        {
                            "group": "bestPractices",
                            "metric": [
                                "supplier_closedLoopWaterSystem_validation", "supplier_renewableEnergy_validation"
                            ]
                        }
                    ]
                },
                "materials": {
                    "name": "materialsList",
                    "groupDropdown": "global",
                    "schemaName": "traceability",
                    "product": "plm",
                    "columns": [
                        {
                            "group": "materialDescription",
                            "metric": [
                                "materialName","material_category_names", "material_process_extraction_relativeAmounts"
                            ]
                        },
                        {
                            "group": "materialSupply",
                            "metric": [
                                "material_process_finishing_supplier_names", "material_process_matProcessing_supplier_name", "material_process_rmatProcessing_supplier_names", "material_process_extraction_supplier_names"
                            ]
                        },
                        {
                            "group": "material_certificate_names",
                            "metric": [
                                "material_certificate_names"
                            ]
                        }
                    ]
                }
            }

            const dataCollection: {[key: string]: any} = {
                "name": "projectList",
                "groupDropdown": "global",
                "schemaName": "dataCollection",
                "product": "data-collection",
                "columns": [
                    {
                        "group": "projectDescription",
                        "metric": [
                            "projectName", "contArticle", "referenceType"
                        ]
                    },
                    {
                        "group": "dataRecords",
                        "metric": [
                            "dataCollectionURL", "status", "createdAt", "closingDate", "folderURL"
                        ]
                    }
                ]
            }
            const ceiView: {[key: string]: any} = {
                "name": "overview-header",
                "groupDropdown": "global",
                "schemaName": "cei",
                "product": "cei",
                "columns": [
                    {
                        "group": "checklist",
                        "metric": [
                            "name"
                        ]
                    },
                    {
                        "group": "overview",
                        "metric": [
                            "MCI_A"
                        ]
                    },
                    {
                        "group": "feedstock",
                        "metric": [
                            "Recycled_A"
                        ]
                    },
                    {
                        "group": "waste",
                        "metric": [
                            "TU_Waste_A"
                        ]
                    },
                    {
                        "group": "usage_potential",
                        "metric": [
                            "Utility_A"
                        ]
                    }
                ]
            }

            res.data.push(traceabilityMockView['articles']); // for test reasons
            res.data.push(traceabilityMockView['suppliers']); // for test reasons
            res.data.push(traceabilityMockView['materials']); // for test reasons
            res.data.push(dataCollection); // for test reasons
            res.data.push(ceiView)

            /**
             * Add saved column to all table views
             */
            res.data.forEach((view: any) => {
                const savedColumn = {
                    group: 'saved',
                    metric: ['saved']
                }
                const detailColumn = {
                    group: 'detail',
                    metric: ['detail']
                }
                if (view.columns.filter((c: any) => c.group === 'saved').length === 0) view.columns.push(savedColumn)
                if (view.columns.filter((c: any) => c.group === 'detail').length === 0) view.columns.push(detailColumn)
            })

            setPresetViews(res.data);
        });
    };

    const getFirstView = (presetViewsByProduct: any) => {
        const presetViewsGlobal = presetViewsByProduct.filter((p: any) => p.groupDropdown === "global");
        const presetViewsIndicatorsGroup = presetViewsByProduct.filter((p: any) => p.groupDropdown === "indicators");
        const presetViewsStagesGroup = presetViewsByProduct.filter((p: any) => p.groupDropdown === "stages");
        const viewsCustomGroup = presetViewsByProduct.filter((p: any) => p.groupDropdown === "custom");

        let firstView;
        if (presetViewsGlobal.length > 0) firstView = presetViewsGlobal[0]
        else if ((presetViewsIndicatorsGroup.length > 0)) firstView = presetViewsIndicatorsGroup[0]
        else if ((presetViewsStagesGroup.length > 0)) firstView = presetViewsStagesGroup[0]
        else firstView = viewsCustomGroup[0]

        return firstView
    }

    const getSelectedView = (defaultScope?: string) => {
        const presetViewsByProduct = presetViews?.filter((p: any) => {
            if (productSelected === 'plm') {
                const scope = defaultScope ?? h.location.pathname.split('/')[3].slice(0, -1)
                if (p.name.includes(scope)) return p
            } else if (p.product === productSelected) return p
        });

        if (customView) {
            const newView = presetViewsByProduct.filter((v: any) => v.name === customView.name)[0];
            setSelectedView(newView);
            setCustomView(undefined);
        } else {
            setSelectedView(getFirstView(presetViewsByProduct));
        }
    }


    useEffect(() => {
        if (openFiltersModal) {
            setFiltersModified(filtersApplied);
        }
    }, [openFiltersModal]);

    useEffect(() => {
        if (openColumnSelectorModal && customView) {
            setColumnSelectorColumns(customView.columns.length === 1
                ? [customView?.columns[0].id]
                : customView.columns.map((c: any) => c.metric).reduce((prev, next) => ([...prev, ...next]), []));
            setColumnSelectorGrouping(customView.schemaName);
            setDataGroupSelectedColumnSelector(dataGroupSelected);
        }
        else if (openColumnSelectorModal && selectedView) {
            setColumnSelectorColumns(selectedView.columns.map((c: any) => c.metric).reduce((prev, next) => ([...prev, ...next]), []));
            setColumnSelectorGrouping(selectedView.schemaName);
            setDataGroupSelectedColumnSelector(dataGroupSelected);
        } else {
            setColumnSelectorColumns(null);
            setColumnSelectorGrouping(null);
        }
    }, [openColumnSelectorModal]);

    useEffect(() => {
        validate(() => {
            getAllViews();
        })
    }, []);

    useEffect(() => {
        const presetViewsByProduct = presetViews?.filter((p: any) => p.product === productSelected);
        setViewsByProduct(presetViewsByProduct);
        getSelectedView();
    }, [productSelected, presetViews]);

    useEffect(() => {
        const delay = setTimeout(() => {
            if (viewsByProduct.length === 0) setAreThereViews("no")
            else setAreThereViews("yes")
        }, 100)
        return () => clearTimeout(delay);
    }, [viewsByProduct])

    useEffect(() => {
        const schemaSelected = selectedView?.schemaName;
        if (schemaSelected) setDataGroupSelected(schemaSelected);
        if (selectedView !== customView) setCustomView(undefined);
    }, [selectedView]);

    useEffect(() => {
        setDataGroupSelectedColumnSelector(dataGroupSelected);
    }, [dataGroupSelected])


    const updateView = (id: string, data: IPresetView) => {
        const request = Views.updateView(id, data);
        request
            .then((res) => {
                getAllViews();
                Notification.display("success", t("toast-success-view-saved"))
            })
            .catch((err) => Notification.display("error", t("toast-error-save-view")))
    }

    const createView = (data: IPresetView) => {
        const request = Views.createView(data);
        request
            .then((res) => {
                getAllViews();
                Notification.display("success", `${data.name} ${t("toast-success-view-created")}`)
            })
            .catch((err) => Notification.display("error", t("toast-error-save-view")))
    };

    const deleteView = (id: string) => {
        const view = selectedView
        getSelectedView()

        const undoDeleteView = () => {
            clearTimeout(delay)
            setSelectedView(view)
            Notification.display("info", t("toast-success-undo-delete-view"))
        }
        Notification.display("default", t("toast-success-view-deleted"), notificationBtn(undoDeleteView, t("common-undo")))

        const delay = setTimeout(() => {
            const request = Views.deleteView(id);
            request
                .then((res) => {
                    getAllViews();
                })
                .catch((err) => Notification.display("error", t("toast-error-delete-view")))
        }, 3000)
    };

    const saveView = () => {
        if (customView) customView?.id ? updateView(customView?.id, customView) : createView(customView)
    }

    // COLUMN SELECTOR
    const deselectColumns = () => {
        setColumnSelectorColumns([EMetricNames.name]);
    }

    const handleColumnSelectorApply = () => {
        if (columnSelectorColumns && dataGroupSelectedColumnSelector) {
            const newColumnSelection = columnSelectorColumns
                .map((id: EMetricNames) => columnsSchemaById[id])
                .reduce((prev: any, next: any) => {
                    let groupedBy = '';
                    if (dataGroupSelectedColumnSelector === 'indicators') groupedBy = 'indicator';
                    if (dataGroupSelectedColumnSelector === 'stages') groupedBy = 'stage';
                    if (dataGroupSelectedColumnSelector === 'benchmark') groupedBy = 'indicator';
                    if (!prev[next[groupedBy]]) {
                        prev[next[groupedBy]] = { group: next[groupedBy], metric: [] }
                    }
                    prev[next[groupedBy]].metric.push(next.id)
                    return prev;
                }, {});

            const editedView = customView ? {
                ...customView,
                columns: Object.values(newColumnSelection),
                schemaName: dataGroupSelectedColumnSelector
            } as IPresetView : {
                ...selectedView,
                columns: Object.values(newColumnSelection),
                schemaName: dataGroupSelectedColumnSelector
            } as IPresetView;

            setCustomView(editedView);
            setOpenModalSaveChanges({
                ...openModalSaveChanges,
                customView: true
            })
            setSelectedView(editedView);
            setOpenColumnSelectorModal(!openColumnSelectorModal);
        }
    }

    // FILTERS SELECTOR
    const handleApplyFilters = () => {
        setOpenFiltersModal(!openFiltersModal);
        setFiltersApplied({ ...filtersModified });
    }

    const deselectFilters = () => {
        setFiltersModified(initialFilters)
    }

    const cleanFilters = () => {
        setFiltersApplied(initialFilters);
    }

    const cleanGroupFilters = (group: any) => {
        const updatedFilters: any = JSON.parse(JSON.stringify(filtersApplied))
        updatedFilters[group] = []
        setFiltersApplied(updatedFilters)
    }

    const cleanSingleFilter = (key: keyof Filters, value: string) => {
        setFiltersApplied((prev: Filters) => ({
            ...prev,
            [key]: prev[key].filter((v: string) => v !== value)
        }));
        setFiltersModified((prev: Filters) => ({
            ...prev,
            [key]: prev[key].filter((v: string) => v !== value)
        }));
    };

    // Open - Close columns selector modal
    const handleCloseColumnSelectorModal = (e: any) => {
        const { className: el } = e.target;
        if (el !== "backdrop" && el !== "modal-lateral-close" && el !== "btn btn-tertiary") return;
        setOpenColumnSelectorModal(!openColumnSelectorModal);
    };

    // Open - Close filters modal
    const handleCloseFiltersModal = (e: any) => {
        const { className: el } = e.target;
        if (el !== "backdrop" && el !== "modal-lateral-close" && el !== "btn btn-tertiary") return;
        setOpenFiltersModal(!openFiltersModal);
    };

    // Seleccionar y deseleccionar todas las columnas por dataType
    const toggleSelectedAllColumn = (columnIdArr: EMetricNames[]) => {
        if (columnSelectorColumns) {
            const metricsNotIncluded = columnIdArr.filter((metric: EMetricNames) => !columnSelectorColumns.includes(metric));
            if (metricsNotIncluded.length > 0) {
                setColumnSelectorColumns([...columnSelectorColumns.concat(metricsNotIncluded)]);
            } else {
                setColumnSelectorColumns(columnSelectorColumns.filter(metric => !columnIdArr.includes(metric)));
            }
        }
    }


    const viewColumnsSchema = selectedView?.columns;
    const schemaNameSelected = selectedView?.schemaName;
    const schema = schemaNameSelected ? schemaGroups[schemaNameSelected].schemaType : schemaGroups['indicators'];
    const displayColumns: Array<any> = [];
    const selectedMetrics: Array<any> = [];
    const columnsList: Array<any> = [];


    const viewColumnsSchemaOrdered: Array<any> = []
    columnsSchema.forEach(cs => {
        viewColumnsSchema?.forEach(vcs => {
            if (vcs.group === cs.indicator) {
                if (viewColumnsSchemaOrdered.filter(g => g.group === (cs.indicator)).length === 0) viewColumnsSchemaOrdered.push({ group: cs.indicator, metric: [] });
                if (vcs.metric.includes(cs.id)) viewColumnsSchemaOrdered.map(vcso => { if (vcso.group === cs.indicator) vcso.metric.push(cs.id) })
            } else if (vcs.group === cs.stage) {
                if (viewColumnsSchemaOrdered.filter(g => g.group === (cs.stage)).length === 0) viewColumnsSchemaOrdered.push({ group: cs.stage, metric: [] });
                if (vcs.metric.includes(cs.id)) viewColumnsSchemaOrdered.map(vcso => { if (vcso.group === cs.stage) vcso.metric.push(cs.id) })
            }
        })
    })

    viewColumnsSchemaOrdered?.forEach((col: any) => {
        if (schema[col.group]) displayColumns.push(schema[col.group]);
        col.metric.forEach((m: any) => selectedMetrics.push(m));
    });

    displayColumns?.forEach((c: any) => c.columns.forEach((cc: any) => {
        for (let i = 0; i < selectedMetrics.length; i++) {
            const columnMetric = selectedMetrics[i];
            if (cc.id === columnMetric) columnsList.push(cc);
        }

        const swap = (val1: any, val2:any, arr:any[]) => {
            if(!arr.includes(val1) || !arr.includes(val2)) return;
            const val1_index = arr.indexOf(val1);
            const val2_index = arr.indexOf(val2);
            arr.splice(val1_index, 1, val2);
            arr.splice(val2_index, 1, val1);
        }
        if( columnsList.length > 1)
        for(let i = 1; i < columnsList.length; i=i+2){
            if( columnsList[i].label == 'qualification' &&  columnsList[i+1] && columnsList[i+1].label == 'ecoScore'){
                swap(columnsList[i], columnsList[i+1], columnsList);
            }
        }
    }));


    //PAGINATION
    const firstPage = () => {
        setCurrentPage(1);
        setFiltersApplied(initialFilters);
    };

    const prevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            setFiltersApplied(initialFilters);
        }
    };

    const nextPage = () => {
        if (currentPage < pageCount) {
            setCurrentPage(currentPage + 1);
            setFiltersApplied(initialFilters);
        }
    };

    const lastPage = () => {
        setCurrentPage(pageCount);
        setFiltersApplied(initialFilters);
    };

    const changePaginationLimit = (numberArticles: number) => {
        setLimitPagination(numberArticles);
        setCurrentPage(1);
    };

    useEffect(() => {
        setCurrentPage(1)
    }, [filtersApplied, searchText]);

    //QUERYSTRING
    const and: any[] = [{ projectId: { '$eq': projectSelected?.id } }];
    if (searchText) {
        and.push({
            '$or': [
                { name: { '$contL': searchText } },
                { reference: { '$contL': searchText } }
            ]
        })
    }

    Object.entries(filtersApplied).forEach(([key, value]) => {
        if (value.length > 0) {
            and.push({ [key]: { '$in': value } })
        }
    })

    const queryString: string = RequestQueryBuilder.create({
        search: {
            '$and': and
        },
        sort: sortMetric,
        page: currentPage,
        limit: limitPagination,
        resetCache: true
    }).query();

    const value = {
        res,
        setRes,
        items,
        pageCount,
        selectedView,
        setSelectedView,
        productSelected,
        presetViews,
        viewsByProduct,
        displayColumns,
        viewColumnsSchema,
        columnsList,
        searchText,
        setSearchText,
        sortColumnId,
        setsortColumnId,
        sortMetric,
        setSortMetric,
        currentPage,
        setCurrentPage,
        limitPagination,
        setLimitPagination,
        firstPage,
        prevPage, nextPage, lastPage,
        changePaginationLimit,
        openColumnSelectorModal, setOpenColumnSelectorModal,
        handleCloseColumnSelectorModal,
        modalViewActionSelected, setModalViewActionSelected,
        dataGroupSelected, setDataGroupSelected,
        deleteView,
        updateView,
        createView,
        columnSelectorColumns,
        customView, setCustomView,
        saveView,
        toggleSelectedColumn: (columnId: EMetricNames) => {
            if (columnSelectorColumns?.includes(columnId)) {
                setColumnSelectorColumns(columnSelectorColumns?.filter(x => x !== columnId))
            } else {
                if (columnSelectorColumns !== null && columnSelectorColumns) {
                    setColumnSelectorColumns([...columnSelectorColumns, columnId]);
                }
            }
        },
        handleColumnSelectorApply,
        dataGroupSelectedColumnSelector, setDataGroupSelectedColumnSelector,
        deselectColumns,
        toggleSelectedAllColumn,
        handleCloseFiltersModal, openFiltersModal, setOpenFiltersModal,
        deselectFilters, cleanGroupFilters, cleanFilters,
        handleApplyFilters,
        filtersApplied,
        setFilter: (field: any, values: any) => setFiltersModified({ ...filtersModified, [field]: values }),
        filtersModified,
        newViewName,
        setNewViewName,
        getSelectedView,
        areThereViews,
        isLoading,
        handleLoading,
        setFiltersApplied,
        sectionFilterSelectedToOpen,
        setSectionFilterSelectedToOpen,
        enableBenchmarkData,
        setEnableBenchmarkData,
        openDetailScopeModal,
        setOpenDetailScopeModal,
        itemSelected,
        setItemSelected,
        infiniteScrollLoading,
        setInfiniteScrollLoading,
        cleanSingleFilter
    };


    return <TableContext.Provider value={value}>{children}</TableContext.Provider>
}
