import { useAccount } from 'contexts/accountContext';
import { useProjects } from 'contexts/projectsContext';
import { useTable } from 'contexts/tableContext';
import { useMenuState } from 'hooks/useMenuState.hook';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SidebarDisplayer } from 'UI/atoms/SidebarDisplayer';
import { SimpleTooltip } from 'UI/atoms/Tooltips/Index';
import { Breadcrumb } from 'UI/molecules/Breadcrumb/Index';
import EditProfileLogout from 'UI/molecules/EditProfileLogout';
import FiltersSection, { SelectedTag } from 'UI/molecules/FiltersSection/Index';
import { NewGenericPdfDownloader } from 'UI/molecules/OnePageHeader/NewGenericPdfDownloader';
import { ScopeSelection } from 'UI/molecules/ScopeSelection/ScopeSelection';
import { SelectorColumnsModal } from 'UI/molecules/SelectorColumnsModal/Index';
import { SelectorFiltersModal } from 'UI/molecules/SelectorFiltersModal/Index';
import TableDashboardSelector, {
	TableDashboardSelectorDisabled,
} from 'UI/molecules/TableDashboardSelector';
import { ModalLateral } from 'UI/organism/ModalLateral/Index';
import { ModalViewAction } from 'UI/organism/ModalViewAction';
import HelpCenter from 'UI/Template/HelpCenter/Index';
import { formatDate } from 'utils/utils';
import {
	HELP_LINKS,
	REFERENCES_TYPE,
	SCOPES_BY_ARTICLE,
	downloadFileName,
} from '../../../constants';
import AppPaths from '../../../permissions/roles/AppPaths';
import { ScopeModal } from '../ScopeModal/ScopeModal';
import { Bell } from './Bell';

const NAME_BY_PATH = [
	{
		name: 'Data Collection',
		path: '/traceability/data-collection',
		feature: 'data_collection',
	},
	{ name: 'PLM', path: '/traceability/plm', feature: 'plm' },
	{ name: 'Journey Map', path: '/traceability/journey-map', feature: '' },
	{
		name: 'Life Cycle Assessment',
		path: '/metrics/lca/dashboard/',
		feature: 'lca',
	},
	{
		name: 'Life Cycle Assessment',
		path: '/metrics/lca/articles',
		feature: 'lca',
	},
	{ name: 'Benchmark', path: '/metrics/benchmark/articles', feature: '' },
	{ name: 'Circularity', path: '/cei', feature: 'cei' },
	{
		name: 'Eco-score',
		path: '/evaluation/sustainabilityindex/dashboard',
		feature: 'eco_score',
	},
	{
		name: 'Eco-score',
		path: '/evaluation/sustainabilityindex/articles',
		feature: 'eco_score',
	},
	{ name: 'Reporting', path: '/diagnosis/reporting', feature: '' },
	{
		name: 'Labeling',
		path: '/digitization/smartlabels/articles',
		feature: 'labeling',
	},
	{
		name: 'Ecommerce',
		path: '/digitization/ecommerce/integration',
		feature: 'integration',
	},
	{
		name: 'Ecommerce',
		path: '/digitization/ecommerce/customization',
		feature: 'integration',
	},
	{ name: 'Academy', path: '/ecodesign/academy', feature: '' },
	{ name: 'Glossary', path: '/ecodesign/glossary', feature: '' },
	{
		name: 'Stage comparator',
		path: '/ecodesign/calculator',
		feature: 'stage_comparator',
	},
	{ name: 'Library', path: '/library', feature: 'library' },
];

const pathWithTableMenu = ['digitization'];
const LIBRARY = 'library';

const PageHeader = (props: any) => {
	const {
		setOpenColumnSelectorModal,
		isLoading,
		handleCloseColumnSelectorModal,
		modalViewActionSelected,
		openColumnSelectorModal,
		handleCloseFiltersModal,
		openFiltersModal,
		openDetailScopeModal,
		setOpenDetailScopeModal,
		itemSelected,
	} = useTable();
	const { projectSelected, showHeader } = useProjects();
	const [referenceType, setReferenceType] = useState('');
	const { user } = useAccount();
	const { t } = useTranslation();
	const wPath = window.location.pathname;
	const generalProduct = wPath.split('/')[1];
	const product = wPath.split('/')[2];
	const scope = wPath.split('/')[3];

	const date = formatDate(); 
	const projectName = projectSelected?.name || 'MyProject';
	const productText = downloadFileName[product] || product || 'bcome';
	const buildedFileName = `BCome ${productText} Dashboard - ${projectName} - ${date}`;

	const [clickNotification, setClickNotification] = useState<boolean>(false);
	const notificationRef = useRef<HTMLDivElement>(null);
	const tooltipId = 'notification-bell';
	const [isTooltipOpen, setIsTooltipOpen] = React.useState<boolean>(false);

	const { setIsSideBar, isSideBar } = props;

	const marginLeft = isSideBar ? '-18px' : '12px';

	const productsWithSelectorProject = [
		'lca',
		'benchmark',
		'sustainabilityindex',
		'smartlabels',
		'plm',
		'data-collection',
		'cei',
		'dashboard',
	];
	const productsWithActiveTableDashboardSelector = [
		'lca',
		'benchmark',
		'sustainabilityindex',
		'plm',
		'cei',
		'data-collection',
	];
	const productsWithDisabledDashboardSelector = [''];

	const productsHeaderTitleWithoutData = [
		'reporting',
		'journey-map',
		'academy',
		'glossary',
		'calculator',
	];

	useEffect(() => {
		if (projectSelected) {
			const copyReferenceType =
				projectSelected.referenceType?.toLowerCase() ?? '--';
			setReferenceType(copyReferenceType);
			if (
				product === 'plm' &&
				REFERENCES_TYPE.includes(copyReferenceType) &&
				scope === 'articles'
			) {
				window.location.href = AppPaths.traceabilityMaterials;
			}
		}
	}, [projectSelected]);

	const handleCallToAction = () =>
		NAME_BY_PATH.find((item) => wPath.includes(item.path)) ?? {
			name: '',
			path: '',
			feature: '',
		};

	const { callToAction } = useCallback(() => {
		return useMenuState(handleCallToAction().feature);
	}, [wPath])();
	const handleScopes = () => {
		if (product === 'plm' && REFERENCES_TYPE.includes(referenceType)) {
			if (SCOPES_BY_ARTICLE[product].includes('articles')) {
				return SCOPES_BY_ARTICLE[product].filter(
					(item: string) => item !== 'articles'
				);
			}
		}
		return SCOPES_BY_ARTICLE[product] ?? null;
	};

	const validatePlmPath = () => {
		return (product === 'plm' && (scope === 'articles' || scope === 'materials' || scope === 'suppliers')) ||
			(product === 'smartlabels' && scope === 'articles');
	};

	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (
				notificationRef.current &&
				!notificationRef.current.contains(event.target as Node)
			) {
				setClickNotification(false);
			}
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	const handleMouseAction = (value: boolean) => setIsTooltipOpen(value);

	const handleToggleColumns = () => {
		setOpenColumnSelectorModal(true);
	};

	const help = HELP_LINKS[`${product}_${scope ?? ''}`] ?? {
		question: '',
		link: '',
	};

	const getDashboardId = (product: string) => {
		switch (product) {
			case 'plm':
				return 'traceability-dashboard';
			case 'cei':
				return 'cei-dashboard';
			case 'lca':
				return 'metrics-dashboard';
			default:
				return 'traceability-dashboard';
		}
	};

	const dashboardId = getDashboardId(product);

	const dashboardText =
		wPath.includes('/plm/dashboard')
			? 'Article collection sampler'
			: wPath.includes('/lca/dashboard') ||
				wPath.includes('/metrics/cei/dashboard') ||
				wPath.includes('/evaluation/sustainabilityindex/dashboard/')
				? 'Total units of article produced'
				: '';

	return (
		<div
			id="header"
			style={{ display: generalProduct === '404' ? 'none' : '' }}
		>
			<div className="d-flex flex-row align-items-center w-100">
				<SidebarDisplayer
					setIsSideBar={setIsSideBar}
					isSideBar={isSideBar}
				/>
				{generalProduct === '' && (
					<div className="w-100 d-flex flex-column justify-content-between align-items-center">
						<HeaderTop special userName={user?.name} />
						<h1 className="mb-0 ml-4 text-dark-100 font-sfpro-regular font-18px w-100 pt-4 mb-4" style={{ height: '42px' }}>
							{t('path-dashboard')} {user?.name}!
						</h1>
					</div>
				)}
				{callToAction ? (
					<h1 className="pb-0 mb-0 ml-4 text-dark-100 font-sfpro-medium font-24px">
						{handleCallToAction().name}
					</h1>
				) : (
					<>
						{generalProduct === 'management' && (
							<h1 className="pb-0 mb-0 ml-4 text-dark-100 font-sfpro-medium font-24px">
								{t('traceability')}
							</h1>
						)}
						{!showHeader && product === 'cei' && (
							<h1 className="pb-0 mb-0 ml-4 text-dark-100 font-sfpro-medium font-24px w-100">
								{t('cei-header')}
							</h1>
						)}
						{!showHeader && product === 'smartlabels' && (
							<>
								<h1 className="pb-0 mb-0 ml-4 mr-4 text-dark-100 font-sfpro-medium font-24px">
									{t('product-selector-smartlabels')}
								</h1>
							</>
						)}
						{!showHeader && product === 'lca' && (
							<>
								<h1 className="pb-0 mb-0 ml-4 mr-4 text-dark-100 font-sfpro-medium font-24px">
									{t('product-selector-lca')}
								</h1>
							</>
						)}
						{!showHeader && product === 'benchmark' && (
							<>
								<h1 className="pb-0 mb-0 ml-4 mr-4 text-dark-100 font-sfpro-medium font-24px">
									{t('sidebar-nav-lca-benchmark')}
								</h1>
							</>
						)}
						{!showHeader && product === 'sustainabilityindex' && (
							<>
								<h1 className="pb-0 mb-0 ml-4 mr-4 text-dark-100 font-sfpro-medium font-24px">
									{t('sustainabilityIndex')}
								</h1>
							</>
						)}

						{product === 'ecommerce' && (
							<div className="d-flex w-100 flex-column align-items-start">
								<HeaderTop userName={user?.name} />
								<h1
									className={`font-18px font-sfpro-regular text-dark-100 text-capitalize ml-3 d-flex align-items-center w-100 pt-4 mb-4`}
									style={{ height: '42px' }}
								>
									{t(`${scope}-header`)}
								</h1>
							</div>
						)}

						{generalProduct === LIBRARY && (
							<div className="w-100 d-flex flex-column align-items-center justify-content-between">
								<HeaderTop userName={user?.name} />
								<h1 className={`pb-0 mb-0 px-3 text-dark-100 font-sfpro-regular font-18px w-100 pt-4 mb-4 text-capitalize`} style={{ height: '42px' }}>
									{t(`library`)}
								</h1>
							</div>
						)}


						{showHeader && productsWithSelectorProject.includes(product) && (
							<div className="d-flex flex-column w-100">
								<HeaderTop userName={user?.name} />
								<div className="d-flex flex-row align-items-center justify-content-between mb-4 ml-2 mr-3 pl-2"
									style={{
										...(generalProduct === 'metrics' ||
											generalProduct === 'evaluation' ||
											generalProduct === 'digitization' ||
											(generalProduct === 'traceability' &&
												product !== 'data-collection')) && {
											borderTop: "1px solid #F0F0F2",
											borderBottom: "1px solid #F0F0F2"
										}
									}}>
									<div className="d-flex flex-row align-items-center flex-wrap">
										{(generalProduct ===
											'metrics' ||
											generalProduct ===
											'evaluation' ||
											generalProduct ===
											'digitization' ||
											(generalProduct ===
												'traceability' &&
												product !==
												'data-collection')) && (
												<FiltersSection
													isLoading={isLoading}
												/>
											)}
										{product ===
											'data-collection' ? (
											<span
												className={`font-18px font-sfpro-regular text-overflow-ellipsis overflow-hidden text-dark-100 text-capitalize d-flex align-items-center`}
												style={{
													height: '42px',
												}}
											>
												{t('data-collection')}
											</span>
										) : generalProduct !==
											LIBRARY ? (
											handleScopes() && !validatePlmPath() && (
												<>
													<ScopeSelection
														scopes={handleScopes()}
														{...{
															projectSelected,
															isLoading,
															visible: false
														}}
													/>
													{['plm', 'lca', 'cei', 'sustainabilityindex'].includes(product) &&
														scope === 'dashboard' && (
															<SelectedTag
																isLoading={isLoading}
																wPath={wPath}
																generalProduct={generalProduct}
																product={product}
															/>
														)}
												</>
											)
										) : (
											<span
												className={`font-20px font-sfpro-medium text-overflow-ellipsis overflow-hidden text-dark-100 text-capitalize ml-3`}
											>
												{t(
													'sidebar-nav-library'
												)}
											</span>
										)}
									</div>
									<div className="mx-2 d-flex align-items-center flex-wrap justify-content-md-end">
										{productsWithActiveTableDashboardSelector.includes(
											product
										) && (
												<TableDashboardSelector
													generalProduct={
														generalProduct
													}
												/>
											)}

										{productsWithDisabledDashboardSelector.includes(
											product
										) && (
												<TableDashboardSelectorDisabled />
											)}
									</div>
								</div>
							</div>
						)}
						{generalProduct === '' ? (
							<div
								className={`text-dark-60 text-dark-100-hover p-2 mr-4 ${clickNotification
									? 'rounded-08 bg-gray-01'
									: ''
									}`}
								onClick={() =>
									setClickNotification(!clickNotification)
								}
								ref={notificationRef}
							>
								<a
									href="https://bcome.biz/help-center/product-updates/release-notes-2024/"
									target="_blank"
									rel="noopener noreferrer"
									className="text-decoration-none d-flex align-items-center text-dark-100-hover"
									id={tooltipId}
									onMouseEnter={() =>
										handleMouseAction(true)
									}
									onMouseLeave={() =>
										handleMouseAction(false)
									}
								>
									<Bell isTooltipOpen={isTooltipOpen} />
								</a>
								<SimpleTooltip
									tooltipId={tooltipId}
									tooltipOpen={isTooltipOpen}
									text={t('product-updates')}
									styles={{
										maxWidth: '250px',
										textAlign: 'start',
									}}
								/>
							</div>
						) : (
							''
						)}
						{productsHeaderTitleWithoutData.includes(
							product
						) && (
								<div
									className={`d-flex flex-column w-100 align-items-center`}>
									<HeaderTop userName={user?.name} />
									<h1 className="font-18px font-sfpro-regular text-dark-100 text-capitalize ml-5 d-flex align-items-center w-100 pt-4 mb-4 " style={{ height: '42px' }}>
										{t(`${product}-header`)}
									</h1>
								</div>
							)}
					</>
				)}
			</div>
			{dashboardText && (
				<div className='d-flex justify-content-between align-items-center px-3'>
					<div className='text-dark-100 font-14px font-sfpro-regular text-overflow-ellipsis overflow-hidden d-flex align-items-center'
						style={{ minHeight: '42px' }}
					>
						{dashboardText}
					</div>
					<NewGenericPdfDownloader
						rootElementId={dashboardId}
						downloadFileName={buildedFileName}
					/>
				</div>
			)}
			{openFiltersModal && (
				<ModalLateral handleClose={handleCloseFiltersModal}>
					<SelectorFiltersModal
						title={'Filters'}
						handleClose={handleCloseFiltersModal}
					/>
				</ModalLateral>
			)}
			{openColumnSelectorModal && (
				<ModalLateral handleClose={handleCloseColumnSelectorModal}>
					<SelectorColumnsModal
						title={'Columns'}
						handleClose={handleCloseColumnSelectorModal}
					/>
				</ModalLateral>
			)}
			{modalViewActionSelected && <ModalViewAction />}
			{openDetailScopeModal && (
				<ModalLateral
					handleClose={() => setOpenDetailScopeModal(false)}
					style={{
						minWidth: '580px',
						maxWidth: '580px',
					}}
				>
					<ScopeModal
						scope={scope.slice(0, scope.length - 1)}
						data={itemSelected}
						handleCloseModal={() => setOpenDetailScopeModal(false)}
					/>
				</ModalLateral>
			)}
		</div>
	);
};

const HeaderTop = ({ special, userName }: { special?: boolean, userName?: string }) => {
	const padding = special ? '16px 0 24px' : '16px 24px 24px 16px';

	return (
		<div
			className="w-100 d-flex justify-content-between align-items-center"
			style={{ padding: padding, minHeight: '48px' }}
		>
			<Breadcrumb />
			<ul className="nav">
				<li className="nav-item">
					<HelpCenter header={true} />
					{/* <a
						className="text-dark-100 nav-link py-0 px-2"
						style={{ color: '#1C1C30' }}
						target="__blank"
						href="https://bcome.biz/es/help-center/"
					>
						Help
					</a> */}
				</li>
				<li className="nav-item">
					<a
						className="text-dark-100 nav-link py-0 px-2"
						style={{ color: '#1C1C30' }}
						href="mailto:info@bcome.biz"
					>
						Contact
					</a>
				</li>
				<li className="nav-item">
					<EditProfileLogout>
						<span className="user-name">
							{userName || 'User'}
						</span>
					</EditProfileLogout>
				</li>
			</ul>
		</div>
	);
};

export default PageHeader;
