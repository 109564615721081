import React, { useEffect, useRef, useState } from "react";
import TableHeaderOptions from "UI/organism/TableHeaderOptions";
import TableHeader from "UI/organism/TableHeader";
import TableBody from "UI/organism/TableBody";
import TableFooter from "UI/organism/TableFooter";
import "./Table.scss";
import { useTable, ResTable } from 'contexts/tableContext';
import { useTranslation } from "react-i18next";
import NoViewsBackground from "UI/molecules/NoViewsBackground";
import { SpinnerLoader } from "components/Common/Loader/Loader";
import { tableLoader } from "loader/table-loader";
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import ArticleHttp from "services/http/Article";
import Favorites from "services/storage/Favorites";
import { useHistory } from "react-router-dom";
import { useProjects } from "contexts/projectsContext";

interface Props {
    isLoading: boolean,
    items: any[]
}

const TableMetrics = ({ items, isLoading }: Props) => {
    const tableContainerRef = useRef<HTMLDivElement | null>(null);
    const { t } = useTranslation();
    const { selectedView, areThereViews, nextPage, res, setRes } = useTable();
    const router = useHistory();
    const { projectSelected } = useProjects();
    const [isLoadingFavorites, setIsLoadingFavorites] = useState(false);

    const [isArtNotFoundMsg, setIsArtNotFoundMsg] = useState(false)

    const artNotFoundMsg = (
        <div className="msg-art-not-found text-dark-100 font-sfpro-regular font-14px">
            <p>{t('common-no-articles-found')}</p>
        </div>
    )

    const handleScroll = () => {
        const container = tableContainerRef.current;
        if (!container) return;

        const scrollTop = container.scrollTop;
        const scrollHeight = container.scrollHeight;
        const clientHeight = container.clientHeight;

        console.log('=== Scroll Debug ===');
        console.log('Current items:', items.length);
        console.log('Total results:', res.total);
        console.log('Selected view:', selectedView?.name);

        if (scrollTop + clientHeight >= scrollHeight) {
            console.log('Loading next page...');
            nextPage();
        }
    };

    useEffect(() => {
        setTimeout(() => {
            if (items.length === 0) setIsArtNotFoundMsg(true)
            else setIsArtNotFoundMsg(false)
        }, 100)
    }, [items])

    useEffect(() => {
        if (res.total === 0 || items.length > res.total) {
            setRes((prev: ResTable) => ({
                ...prev,
                total: items.length
            }));
        }
    }, [items, selectedView]);

    useEffect(() => {
        const container = tableContainerRef.current;
        if (container) {
            container.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (container) {
                container.removeEventListener("scroll", handleScroll);
            }
        };
    }, [res]);

    useEffect(() => {
        const fetchAllFavorites = async () => {
            if (router.location.search.includes('filter=favorites')) {
                try {
                    setIsLoadingFavorites(true);
                    const queryWithoutPagination = RequestQueryBuilder.create({
                        search: {
                            '$and': [{ projectId: { '$eq': projectSelected?.id } }]
                        }
                    }).query();

                    const articleService = new ArticleHttp();
                    const allArticles = await articleService.articlesByProject(queryWithoutPagination);
                    const currentFavorites = Favorites.getCurrentfavorites();
                    console.log('All articles:', allArticles.data.length);
                    console.log('Current favorites before global save:', currentFavorites);
                    const allFavoriteIds = allArticles.data
                        .filter((item: any) => currentFavorites.includes(item.id))
                        .map((item: any) => item.id);
                    
                    console.log('Saving global favorites:', allFavoriteIds);
                    Favorites.saveGlobalFavorites(allFavoriteIds);
                } catch (error) {
                    console.error('Error fetching all favorites:', error);
                } finally {
                    setIsLoadingFavorites(false);
                }
            }
        };

        fetchAllFavorites();
    }, [router.location.search, projectSelected]);

    return (
        <div className="table-footer-container d-flex flex-column h-100 rounded-10" style={{ paddingBottom: '10px' }}>
            <div key={selectedView?.group} className="d-flex flex-column overflow-hidden flex-grow-1 bg-white-100 rounded-02 box-shadow-4">
                <TableHeaderOptions isLoading={isLoading} />
                <div ref={tableContainerRef} className="table-v1-3 d-flex flex-column border-bottom" style={{
                    marginBottom: (!isLoading && !isLoadingFavorites) ? 0 : '3rem',
                }}>
                    {(isLoading || isLoadingFavorites) &&
                        <div className="m-auto">
                            <SpinnerLoader color={"#1C1C30"} width={"60px"} height={"60px"} />
                        </div>
                    }
                    {!isLoading && !isLoadingFavorites && (areThereViews == "yes" || selectedView) &&
                        <>
                            <table>
                                <TableHeader key={selectedView?.id} />
                                <TableBody items={items} />
                            </table>
                        </>
                    }
                    {!isLoading && !isLoadingFavorites && (areThereViews == "yes" || selectedView) && isArtNotFoundMsg && artNotFoundMsg}
                    {!isLoading && !isLoadingFavorites && areThereViews === "no" && !selectedView && <NoViewsBackground />}
                </div>
                <TableFooter numberOfItems={items.length} />
            </div>
        </div>
    )
}

export default tableLoader(TableMetrics);