import React from 'react';
import { useHistory } from 'react-router-dom';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useProjects } from 'contexts/projectsContext';
import { useTable } from 'contexts/tableContext';
import {
    useDownloadExcel,
    useDownloadSmartLabelsExcel,
} from 'hooks/useDownloadFile.hook';

type GenericPdfDownloaderProps = {
    rootElementId: string;         // ID del contenedor que convertirás en PDF
    downloadFileName: string;      // Nombre de archivo resultante
    tableDownload?: boolean;       // Para indicar si es descarga tipo Excel
    disabled?: boolean;            // Desactivar el botón si necesitas
};

function handleBorder(
    elements: HTMLCollectionOf<Element>,
    action: 'add' | 'remove'
) {
    // Añade o quita la clase CSS "border" a los elementos (si la usas para el PDF)
    Array.prototype.forEach.call(elements, (element: any) => {
        element.classList[action]('border');
    });
}

export const NewGenericPdfDownloader: React.FC<GenericPdfDownloaderProps> = ({
    rootElementId,
    downloadFileName,
    tableDownload = false,
    disabled = false,
}) => {
    const h = useHistory();
    const { projectSelected } = useProjects();
    const { handleLoading } = useTable();

    const path = h.location.pathname;

    /**
     * Descarga de datos en Excel (para tablas).
     * Sólo se activa si tableDownload === true
     */
    const handleExportData = () => {
        if (!projectSelected?.id) return;

        if (path.includes('smartlabels')) {
            handleLoading(true);
            useDownloadSmartLabelsExcel(
                projectSelected.id,
                projectSelected.name,
                handleLoading
            );
        } else {
            handleLoading(true);
            useDownloadExcel(
                projectSelected.id,
                projectSelected.name,
                handleLoading
            );
        }
    };

    /**
     * Captura el contenido del DOM y genera el PDF con jsPDF.
     */
    const downloadPdfDocument = () => {
        // 1. Validar que el elemento existe
        const input = document.getElementById(rootElementId);
        if (!input) {
            console.error('[PDF Download] Error: Elemento no encontrado:', rootElementId);
            return;
        }

        // 2. Validar que el elemento es visible y tiene dimensiones
        const rect = input.getBoundingClientRect();

        if (rect.width === 0 || rect.height === 0) {
            console.error('[PDF Download] Error: El elemento tiene dimensiones inválidas');
            return;
        }

        try {
            // 3. Validar que todas las imágenes están cargadas
            const images = input.getElementsByTagName('img');
            const imagesLoaded = Array.from(images).every(img => img.complete);

            // 4. Agregar clase temporal para estilos de impresión
            input.classList.add('pdf-capture');

            const cards = input.getElementsByClassName(
                'd-flex flex-column overflow-hidden w-100 h-100 rounded-08 box-shadow'
            );
            handleBorder(cards, 'add');

            if (rootElementId === 'OnePageArticle') {
                const footerCards = input.getElementsByClassName(
                    'd-flex flex-column flex-grow-1 box-shadow text-dark-100 bg-white-100 rounded-08 p-4 m-2'
                );
                handleBorder(footerCards, 'add');
            }

            const pdfType =
                rootElementId.includes('traceability') || 
                rootElementId === 'OnePageArticle' ||
                rootElementId === 'cei-dashboard'
                    ? 'landscape'
                    : 'portrait';

            const pdf = new jsPDF(pdfType);

            const options = {
                scale: 2, // Mejor calidad
                useCORS: true, // Permitir imágenes cross-origin
                logging: true, // Habilitar logs de html2canvas
                onclone: (clonedDoc: Document) => {
                    return clonedDoc;
                }
            };

            html2canvas(input, options)
                .then((canvas: HTMLCanvasElement) => {

                    const widthInMm = canvas.width * 0.2645833333;
                    const heightInMm = canvas.height * 0.2645833333;

                    // Para LCA (metrics-dashboard), ajustamos el contenido para asegurar que se vea completo
                    if (rootElementId === 'metrics-dashboard') {
                        const pageWidth = pdf.internal.pageSize.getWidth();
                        const pageHeight = pdf.internal.pageSize.getHeight();
                        
                        // Calculamos el factor de escala manteniendo el aspect ratio
                        const scaleWidth = pageWidth / widthInMm;
                        const scaleHeight = pageHeight / heightInMm;
                        const scale = Math.min(scaleWidth, scaleHeight) * 0.95; // 95% del tamaño máximo para dejar margen
                        
                        // Calculamos las dimensiones finales
                        const finalWidth = widthInMm * scale;
                        const finalHeight = heightInMm * scale;
                        
                        // Calculamos los márgenes para centrar
                        const marginLeft = (pageWidth - finalWidth) / 2;
                        const marginTop = (pageHeight - finalHeight) / 2;

                        pdf.addImage(canvas, 'PNG', marginLeft, marginTop, finalWidth, finalHeight);
                        pdf.save(`${downloadFileName}.pdf`);
                        return;
                    }

                    // Lógica existente para otros dashboards
                    if (
                        (widthInMm > heightInMm && heightInMm < 210) ||
                        (widthInMm < heightInMm && heightInMm < 210)
                    ) {
                        const canvasHeight = Number(((heightInMm * 297) / widthInMm).toFixed(0));
                        if (canvasHeight > 210) {
                            const canvasWidth = Number(((widthInMm * 210) / heightInMm).toFixed(0));
                            const marginLeftRight = (297 - canvasWidth) / 2;
                            pdf.addImage(canvas, 'PNG', marginLeftRight, 0, canvasWidth, 210);
                            pdf.save(`${downloadFileName}.pdf`);
                        } else {
                            const marginTopBottom = (210 - canvasHeight) / 2;
                            pdf.addImage(canvas, 'PNG', 0, marginTopBottom, 297, canvasHeight);
                            pdf.save(`${downloadFileName}.pdf`);
                        }
                    } else {
                        const canvasWidth = Number(((widthInMm * 210) / heightInMm).toFixed(0));

                        if (canvasWidth > 297) {
                            const canvasHeight = Number(
                                ((heightInMm * 297) / widthInMm).toFixed(0)
                            );
                            const marginTopBottom = (210 - canvasHeight) / 2;
                            pdf.addImage(canvas, 'PNG', 0, marginTopBottom, 297, canvasHeight);
                            pdf.save(`${downloadFileName}.pdf`);
                        } else {
                            const marginLeftRight = (pdf.internal.pageSize.getWidth() - canvasWidth) / 2;
                            pdf.addImage(canvas, 'PNG', marginLeftRight, 0, canvasWidth, 210);
                            pdf.save(`${downloadFileName}.pdf`);
                        }
                    }
                })
                .catch((error) => {
                    console.error('[PDF Download] Error en html2canvas:', error);
                })
                .finally(() => {
                    // Limpiar clases temporales
                    input.classList.remove('pdf-capture');
                    handleBorder(cards, 'remove');
                    if (rootElementId === 'OnePageArticle') {
                        const footerCards = input.getElementsByClassName(
                            'd-flex flex-column flex-grow-1 box-shadow text-dark-100 bg-white-100 rounded-08 p-4 m-2'
                        );
                        handleBorder(footerCards, 'remove');
                    }
                });
        } catch (error) {
            console.error('[PDF Download] Error crítico:', error);
        }
    };

    return (
        <span
            className={`icon-icon-download font-20px mx-1 ${disabled ? 'non-click text-dark-40' : 'cursor-pointer text-dark-60 text-dark-100-hover'
                }`}
            onClick={tableDownload ? handleExportData : downloadPdfDocument}
        />
    );
};
